export default [
  "Acura",
  "BMW",
  "Chevrolet",
  "Ford",
  "GMC",
  "Infiniti",
  "Lexus",
  "Mercedes",
  "Nissan",
  "Porsche",
  "Tesla",
  "Toyota",
  "Volkswagon"
];
