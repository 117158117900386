<template>
  <div>
    <PageHeader title="Application">
      <div :class="status === 'Draft' ? 'error' : 'success'" class="rounded text-h6 white--text px-3 py-2">
        {{ status === 'Draft' ? 'Pending' : 'Submitted' }}
        <!-- {{status}} -->
      </div>
    </PageHeader>

    <v-form ref="customerInfoForm" v-model="customerInfoForm">
      <v-card color="cardBackground">
        <v-card-text>
          <v-card tile flat color="cardBackground">
            <v-card-title>
              <div class="d-flex" style="width: 100%">
                <div>Vehicle Details</div>
                <div class="ml-auto">
                  <v-progress-circular v-if="updatingVehicleDetails" indeterminate color="info" size="25"></v-progress-circular>
                </div>
              </div>
            </v-card-title>
            <v-card-subtitle>This is the basic details on your purchase</v-card-subtitle>
            <v-card-text>
              <v-row>
                <!-- <v-col cols="12" xs="12" sm="4">
                  <v-select
                    dense
                    outlined
                    required
                    type="tel"
                    label="Year"
                    class=""
                    v-model="app.year"
                    @input="updateVehicleDetails()"
                    @blur="storePartialForm('vehicle')"
                    :items="yearChoices"
                    :rules="rules.year"
                    :disabled="requestPending || appSubmitted"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <v-select
                    dense
                    outlined
                    required
                    label="Make"
                    class=""
                    v-model="app.make"
                    @input="updateVehicleDetails()"
                    @blur="storePartialForm('vehicle')"
                    :items="makeChoices"
                    :rules="rules.make"
                    :disabled="requestPending || appSubmitted"
                  ></v-select>
                </v-col> -->

                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    label="Year"
                    counter="4"
                    class=""
                    v-model="app.year"
                    @input="updateVehicleDetails()"
                    @blur="storePartialForm('vehicle')"
                    :rules="rules.model"
                    :maxlength="40"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>

                                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    label="Model"
                    counter="20"
                    class=""
                    v-model="app.make"
                    @input="updateVehicleDetails()"
                    @blur="storePartialForm('vehicle')"
                    :rules="rules.model"
                    :maxlength="40"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    label="Model"
                    counter="40"
                    class=""
                    v-model="app.model"
                    @input="updateVehicleDetails()"
                    @blur="storePartialForm('vehicle')"
                    :rules="rules.model"
                    :maxlength="40"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card tile flat class="" color="cardBackground">
            <v-card-title>
              <div class="d-flex" style="width: 100%">
                <div>Shipping Address</div>
                <div class="ml-auto">
                  <v-progress-circular v-if="updatingShippingAddress" indeterminate color="info" size="25"></v-progress-circular>
                </div>
              </div>
            </v-card-title>
            <v-card-subtitle>This is the name and address that your plates will be shipped to</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    autofocus
                    name="fullName"
                    label="Full Name"
                    maxlength="50"
                    class=""
                    v-model="app.fullName"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="rules.name"
                    :counter="50"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    type="tel"
                    name="phone number"
                    label="Phone number"
                    class=""
                    v-mask="mask"
                    v-model="app.phoneNumber"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="rules.phoneNumber"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="address-line-1"
                    label="Address Line 1"
                    class=""
                    v-model="app.addressOne"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="rules.address"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    name="address-line-2"
                    label="Address Line 2"
                    class=""
                    v-model="app.addressTwo"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="app.addressTwo ? rules.address : []"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="city"
                    label="City"
                    class=""
                    v-model="app.city"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="rules.city"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="state"
                    label="State"
                    class="state"
                    v-model="app.state"
                    @keyup="uppercase()"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="rules.state"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    type="tel"
                    name="zipcode"
                    label="Zip Code"
                    maxlength="5"
                    class=""
                    v-model="app.zip"
                    @input="updateShippingAddress()"
                    @blur="storePartialForm('address')"
                    :rules="rules.zip"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card tile flat color="cardBackground">
            <v-card-title>Bill of Sale <BosExampleModal></BosExampleModal></v-card-title>
            <v-card-subtitle>Upload the document outlining the cost details of your purchase <br> (Note: If you are submitting a 2-page Bill of Sale as image, please upload the page 1 and page 2 image of the document seperately.)</v-card-subtitle>
            <v-card-text>
              <div v-if="!downloadUrl || !appSubmitted" class="mb-6">
                <!-- <UploadBosModal text="Upload your Bill of Sale"></UploadBosModal> -->
                <v-row>
                  <v-col cols="12" sm="6">
                    <!-- <v-btn outlined color="success" :href="downloadUrl" class="mr-4">Upload your bill of sale</v-btn> -->
                    <v-file-input
                      dense
                      outlined
                      required
                      clearable
                      show-size
                      persistent-hint
                      hint="File must be a pdf, png, jpg, or a jpeg"
                      ref="bosFileToUpload"
                      type="file"
                      name="bill-of-sale"
                      label="Click to select file"
                      accept="image/*, .pdf, .jpg, .jpeg, .HEIF, .HEIC, capture, camera=capture"
                      v-model="chosenFile"
                      :rules="rules.file"
                      :disabled="uploadInProgress || appSubmitted"
                      @click:clear="clearClicked()"
                    ></v-file-input>
                    <v-text-field
                      dense
                      required
                      disabled
                      name="bosFileType"
                      label="Bill Of Sale file type"
                      v-show="false"
                      v-model="app.bosFileType"
                      :rules="rules.bosFileType"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12" sm="6">
                    <v-btn
                      large
                      type="submit"
                      class="success"
                      block
                      :disabled="!customerInfoForm || appSubmitted"
                      :loading="requestPending"
                      @click.native.prevent="submitApplication()"
                    >
                      Submit My Application
                    </v-btn>
                  </v-col> -->
                </v-row>
                <div class="ml-2">
                  <ImagePreview v-if="imagePreview" :image-url="imagePreview" title="Image Preview"></ImagePreview>
                </div>
              </div>

              <ImagePreview v-if="downloadUrl" :image-url="downloadUrl" title="You uploaded your Bill of Sale"></ImagePreview>
            </v-card-text>


              <!-- <v-card-text>
              <div v-if="!downloadUrl || !RegistrationAmount" class="mb-6">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-file-input
                      dense
                      outlined
                      required
                      clearable
                      show-size
                      persistent-hint
                      hint="File must be a pdf, png, jpg, or a jpeg"
                      ref="bosFileToUpload"
                      type="file"
                      name="bill-of-sale"
                      label="Click to select file"
                      accept="image/*, .pdf, .jpg, .jpeg, .HEIF, .HEIC, capture, camera=capture"
                      v-model="chosenFile"
                      :rules="rules.file"
                      :disabled="uploadInProgress || appSubmitted"
                      @click:clear="clearClicked()"
                    ></v-file-input>
                    <v-text-field
                      dense
                      required
                      disabled
                      name="bosFileType"
                      label="Bill Of Sale file type"
                      v-show="false"
                      v-model="app.bosFileType"
                      :rules="rules.bosFileType"
                    ></v-text-field>
                  </v-col>

           
                </v-row>
                <div class="ml-2">
                  <ImagePreview v-if="imagePreview" :image-url="imagePreview" title="Image Preview"></ImagePreview>
                </div>
              </div>

              <ImagePreview v-if="downloadUrl" :image-url="downloadUrl" title="You uploaded your Bill of Sale"></ImagePreview>
            </v-card-text> -->

 <!-- Page 2 of BOS -->
             <v-card-text>
              <div v-if="!downloadUrl2 || !appSubmitted" class="mb-6">
                <!-- <UploadBosModal text="Upload your Bill of Sale"></UploadBosModal> -->
                <v-row>
                  <v-col cols="12" sm="6">
                    <!-- <v-btn outlined color="success" :href="downloadUrl" class="mr-4">Upload your bill of sale</v-btn> -->
                    <v-file-input
                      dense
                      outlined
                      clearable
                      show-size
                      persistent-hint
                      hint="File must be a pdf, png, jpg, or a jpeg"
                      ref="bosFileToUpload2"
                      type="file"
                      name="bill-of-sale2"
                      label="Click to select file"
                      accept="image/*, .pdf, .jpg, .jpeg, .HEIF, .HEIC, capture, camera=capture"
                      v-model="chosenFile2"
                      :rules="rules.file2"
                      :disabled="uploadInProgress || appSubmitted"
                      @click:clear="clearClicked()"
                    ></v-file-input>
                    <v-text-field
                      dense
                      required
                      disabled
                      name="bosFileType2"
                      label="Bill Of Sale file type"
                      v-show="false"
                      v-model="app.bosFileType2"
                      :rules="rules.bosFileType2"

                    ></v-text-field>
                  </v-col>
</v-row>

                         <div class="ml-2">
                  <!-- <ImagePreview v-if="imagePreview2" :image-url="imagePreview2" title="Image Preview"></ImagePreview> -->
                                    <ImagePreview v-if="imagePreview2" :image-url="imagePreview2" title="Image Preview"></ImagePreview>

                </div>
              </div>

              <!-- <ImagePreview v-if="downloadUrl2" :image-url="downloadUrl2" title="You uploaded your Bill of Sale (Page 2)"></ImagePreview> -->
                <ImagePreview v-if="downloadUrl2" :image-url="downloadUrl2" title="You uploaded your Bill of Sale (Page 2)"></ImagePreview>

  
  </v-card-text>

                  <v-col cols="12" sm="6">
                    <v-btn
                      large
                      type="submit"
                      class="success"
                      block
                      :disabled="!customerInfoForm || appSubmitted"
                      :loading="requestPending"
                      @click.native.prevent="submitApplication()"
                    >
                      Submit My Application
                    </v-btn>
                  </v-col>
              
                          <!-- <v-col cols="12" sm="6">
                    <v-btn
                      large
                      type="submit"
                      class="success"
                      block
                      :disabled="!customerInfoForm || appSubmitted"
                      :loading="requestPending"
                      @click.native.prevent="submitApplication()"
                    >
                      Submit My Application
                    </v-btn>
                  </v-col>    -->
           
           
          </v-card>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions class="mt-6 pa-4 d-flex" :class="[$vuetify.breakpoint.smAndDown ? 'horizontal-flex' : '']">
          <BackButton></BackButton>
          <v-btn
            large
            class="success ml-auto"
            type="submit"
            :disabled="!customerInfoForm || appSubmitted"
            :loading="requestPending"
            @click.native.prevent="submitApplication()"
          >
            Submit My Application
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapState, mapGetters } from 'vuex';
import { statesAndAbbreviations } from './states.json';
import { stateAbbreviations } from './states_abbr.json';
import VueRouter from '../../../../router';

// import ProgressBar from '../../../components/transfer/ProgressBar.vue';
import years from '../../../../data/years.js';
import makes from '../../../../data/makes.js';

export default {
  directives: {
    mask,
  },
  components: {
    BosExampleModal: () => import('./BosExampleModal'),
    PageHeader: () => import('@/components/app/PageHeader'),
    ImagePreview: () => import('./ImagePreview.vue'),    
    // RegistrationHeader: () => import('@/components/app/RegistrationHeader'),
  },
  created() {
    const regId = this.$route.params.regId;
    this.setupData(regId);
    // this.unwatch = this.$store.watch(
    //   state => {
    //     return this.$store.state.billOfSale.downloadUrl; // could also put a Getter here
    //   },
    //   (newValue, oldValue) => {
    //     // console.log(`downloadUrl from ${oldValue} to ${newValue}`);
    //   }
    // );
  },
  mounted() {
    // fix for v-file-input not triggering properly
    let inputs = document.querySelectorAll('.v-file-input input');
    [...inputs].forEach(input => {
      input.remove();
    });
  },
  beforeDestroy() {
    // this.unwatch();
  },
  data: () => ({
    regId: null,
    debounceTimeout: 3000,
    appSubmitted: false,
    requestPending: false,
    yearChoices: years,
    makeChoices: makes,
    mask: '(###) ###-####',
    customerInfoForm: false,
    states: stateAbbreviations,
    updatingVehicleDetails: false,
    updatingShippingAddress: false,
    keyUpTimeout: 0,
    app: {
      year: null,
      make: null,
      model: null,
      fullName: null,
      phoneNumber: null,
      addressOne: null,
      addressTwo: null,
      city: null,
      state: null,
      zip: null,
      bosFileType: null,
      bosFileType2: null,
    },
    // bill of sale
    imageData: null,
    chosenFile: null,
    imagePreview: null,
    bosToUpload: null,
    billOfSaleForm: true,
    uploadInProgress: false,

     // bill of sale 2
    imageData2: null,
    chosenFile2: null,
    imagePreview2: null,
    bosToUpload2: null,
    billOfSaleForm2: true,
    uploadInProgress2: false,

    rules: {
      year: [v => !!v || 'Year is required', v => /^[0-9\s]*$/.test(v) || 'Only numbers are allowed'],
      make: [v => !!v || 'Make is required'],
      model: [v => !!v || 'Model is required', v => /^[a-zA-Z0-9\s#/,.-]*$/.test(v) || 'Only letters, spaces, comma, /, . , - and # are allowed'],
      name: [
        v => !!v || 'Name is required',
        v => /^[a-zA-Z][a-zA-Z\s#/,.-]*$/.test(v) || 'Only letters, spaces, comma, /, . , - and # are allowed',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters',
        // v => /([!@#$%^&*()])/.test(v) || 'Must have one special character "! @ # $ % & * ( )"',

      ],
      email: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      phoneNumber: [v => (v && v.length === 14) || 'Phone number must be 10 numbers long'],
      address: [v => !!v || 'Address 1 is required', v => /^[a-zA-Z0-9\s#/,.-]*$/.test(v) || 'Only letters, spaces, comma, /, . , - and # are allowed'],
      city: [
        v => !!v || 'City is required',
        v => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
        v => (v && v.length <= 50) || 'City must be less than 50 characters',
      ],
      state: [
        v => !!v || 'State is required',
        v => (v && v.length >= 2) || 'State must be atleast 2 characters',
       // v => /^[A-Z]*$/.test(v) || 'Only uppercase letters are allowed',
      ],
      zip: [v => !!v || 'Zip Code is required', v => /^\d{5}$/.test(v) || 'Zip Code must be 5 numbers only'],
      bosFileType: [
        v => !!v || 'Bill of sale is required',
        // v => !v || v.size < 1000000 || "Bill of sale size should be less than 1 MB"
      ],
      // bosFileType2: [
      //   v => !!v || 'Bill of sale is required',
      //   // v => !v || v.size < 1000000 || "Bill of sale size should be less than 1 MB"
      // ],
    },
  }),
  computed: {
    ...mapState('billOfSale', ['downloadUrl']),
    awsUploadUrl() {
      return this.$store.state.dashbaord.awsUploadUrl;
    },
    downloadUrl() {
      return this.$store.state.billOfSale.downloadUrl;
    },
    fileType() {
      return this.$store.state.registration.app.BOSFileType;
    },
    ...mapState('billOfSale2', ['downloadUrl2']),
    awsUploadUrl2() {
      return this.$store.state.dashbaord.awsUploadUrl2;
    },
    downloadUrl2() {
      return this.$store.state.billOfSale.downloadUrl2;
    },
    fileType2() {
      return this.$store.state.registration.app.BOSFileType2;
    },
  },
  watch: {
    fileType: function(type) {
      if (type) {
        // console.log('>> fileType', type);
        this.app.bosFileType = type;
      } else {
        // console.log('>> no value', type);
        this.app.bosFileType = type;
      }
    },
    chosenFile: function(file) {
      if (file) {
        const name = file.name;
        const type = file.type;
        const finalType = this.getFileType(type);
        this.app.bosFileType = finalType;
        const reader = new FileReader();
        reader.onload = e => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.chosenFile = null;
        this.imagePreview = '';
        this.app.bosFileType = null;
      }
    },
        fileType2: function(type) {
      if (type) {
        // console.log('>> fileType', type);
        this.app.bosFileType2 = type;
      } else {
        // console.log('>> no value', type);
        this.app.bosFileType2 = type;
      }
    },
    chosenFile2: function(file) {
      if (file) {
        const name2 = file.name;
        const type2 = file.type;
        const finalType2 = this.getFileType(type2);
        this.app.bosFileType2 = finalType2;
        const reader2 = new FileReader();
        reader2.onload = e => {
          this.imagePreview2 = e.target.result;
        };
        reader2.readAsDataURL(file);
      } else {
        this.chosenFile2 = null;
        this.imagePreview2 = '';
        this.app.bosFileType2 = null;
      }
    },
  },
  methods: {
    clearClicked() {
      // console.log('clearClicked');
    },
    uppercase() {
      this.app.state = this.app.state.toUpperCase();
    },
    updateVehicleDetails() {
      clearTimeout(this.keyUpTimeout);

      this.keyUpTimeout = setTimeout(() => {
        this.updatingVehicleDetails = true;

        this.storePartialForm('updateVehicleDetails')
          .then(res => {
            this.updatingVehicleDetails = false;
          })
          .catch(err => {
            console.error(err);
            this.updatingVehicleDetails = false;
          });
      }, this.debounceTimeout);
    },
    updateShippingAddress() {
      clearTimeout(this.keyUpTimeout);

      this.keyUpTimeout = setTimeout(() => {
        this.updatingShippingAddress = true;

        this.storePartialForm('updateShippingAddress')
          .then(res => {
            this.updatingShippingAddress = false;
          })
          .catch(err => {
            console.error(err);
            this.updatingShippingAddress = false;
          });
      }, this.debounceTimeout);
    },
    storePartialForm(type) {
      clearTimeout(this.keyUpTimeout);
      const payload = {
        ...this.app,
        regId: this.regId,
      };
      return this.$store.dispatch('registration/storePartialApp', payload);
    },
    getFileType(passedType) {
      // console.log('passed: ' + passedType);
      const type = passedType;
      const splitType = type.split('/');
      const fileType = splitType[1];
      const checkForPlus = fileType.split('+');
      const finalType = checkForPlus[0];
      return finalType;
    },
    setupData(regId) {
      const bosFileType = this.fileType;
      const bosFileType2 = this.fileType;

      this.app.year = this.$store.state.registration.app.VehicleYear;
      this.app.make = this.$store.state.registration.app.VehicleMake;
      this.app.model = this.$store.state.registration.app.VehicleModel;
      this.app.fullName = this.$store.state.registration.app.CustomerName;
      this.app.phoneNumber = this.$store.state.registration.app.PhoneNumber;
      this.app.addressOne = this.$store.state.registration.app.ShippingAddrLine1;
      this.app.addressTwo = this.$store.state.registration.app.ShippingAddrLine2;
      this.app.city = this.$store.state.registration.app.ShippingCity;
      this.app.state = this.$store.state.registration.app.ShippingState;
      this.app.zip = this.$store.state.registration.app.ShippingZipCode;
      this.status = this.$store.state.registration.app.Status;
      this.dateCreated = this.$store.state.registration.app.DateCreated;
      this.regId = regId;
      if (this.status !== 'Draft') {
        this.appSubmitted = true;
      }

      if (bosFileType) {
        this.app.bosFileType = bosFileType;
        this.bosUpdateDate = this.$store.state.registration.app.BOSUploaded;
        this.$store
          .dispatch('billOfSale/getCurrent', { regId, bosFileType })
          .then(res => {})
          .catch(err => {
            console.log(err);
          });
      } else {
        this.app.bosFileType = null;
        this.bosUpdateDate = null;
      }

      if (bosFileType2) {
        this.app.bosFileType2 = bosFileType2;
        this.bosUpdateDate = this.$store.state.registration.app.BOSUploaded2;
        this.$store
          .dispatch('billOfSale/getCurrent2', { regId, bosFileType2 })
          .then(res => {})
          .catch(err => {
            console.log(err);
          });
      } else {
        this.app.bosFileType2 = null;
        this.bosUpdateDate = null;
      }
    },
    submitApplication() {
      this.requestPending = true;
      const payload = {
        regId: this.$route.params.regId,
        app: this.app,
        bosFile: this.chosenFile,
        bosFile2: this.chosenFile2,

      };

      this.$store
        .dispatch('registration/submitApplication', payload)
        .then(res => {
          this.requestPending = false;
          this.$store.commit('snackmsg/show', {
            text: 'Your application has been submitted',
          });
          this.$router.push({
            name: 'app.dashboard',
          });
        })
        .catch(err => {
          this.$store.commit('snackmsg/show', {
            text: err,
            color: 'error',
          });
        });
    },
    navToStatus() {
      VueRouter.push({ name: 'app.dashboard', params: { regId: this.regId } });
    },
  },
};
</script>

<style scoped>
.registration-body {
  margin: 0;
}

.image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
.iframe-container {
  width: 350px;
  height: 350px;
  padding: 0;
  overflow: hidden;
}

.iframe-container iframe {
  width: 1400px;
  height: 1400px;
  border: 0;

  -ms-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -o-transform: scale(0.25);
  -webkit-transform: scale(0.25);
  transform: scale(0.25);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.v-input input {
  font-size: 30px;
}

.horizontal-flex {
  flex-direction: column;
}
.horizontal-flex .v-btn {
  width: 100%;
  margin-bottom: 20px;
  margin-left: auto !important;
}

.wrap {
  /* width: 400px; */
  height: 600px;
  padding: 0;
  overflow: hidden;
}

.frame {
  width: 1200px;
  height: 1800px;
  border: 0;
  -ms-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -o-transform: scale(0.25);
  -webkit-transform: scale(0.25);
  transform: scale(0.25);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
</style>
